<template>
  <div class="container">
    <div class="header">
      <van-icon v-if="data.declareState === 1" name="passed" />
      <van-icon v-else-if="data.declareState === 3" name="close" />
      <van-icon v-else name="info-o" />
      <p>{{ data.declareState | filterStatus }}</p>
    </div>
    <van-cell-group>
      <van-cell title="OL端末通関通番" :value="data.clientDeclareNo" />
      <van-cell title="OL端末処理通番" :value="data.clientOrderNo" />
      <van-cell title="検証機関" :value="data.verifyDepartment" />
      <van-cell
        title="検証機関のトランザクションシリアル番号"
        :value="data.verifyDepartmentTradeId"
      />
      <van-cell
        title="認証状態"
        :value="data.certCheckResult === 0 ? 'UNCHECKED' : 'DIFFERENT'"
      />
      <van-cell title="税関申告開始時間" :value="data.declareStartTime" />
      <van-cell title="税関申告最終修正時間" :value="data.declareUpdateTime" />
      <van-cell title="通関完了時間" :value="data.declareFinishTime" />
    </van-cell-group>

    <!-- <van-button type="primary" block @click="handleDone">Done</van-button> -->
  </div>
</template>

<script>
import { customsDeclare } from '@/apis'

export default {
  name: 'Confirmation',
  filters: {
    filterStatus(status) {
      if (status === 0) {
        return '未申告'
      } else if (status === 1) {
        return '申告成功'
      } else if (status === 2) {
        return '申告提出済'
      } else if (status === 3) {
        return '申告失敗'
      } else if (status === 4) {
        return '申告エラー'
      } else if (status === 7) {
        return '申告中'
      } else {
        return 'Unknow status'
      }
    },
  },
  data() {
    return {
      data: {
        clientDeclareNo: '',
        clientOrderNo: '',
        verifyDepartment: '',
        verifyDepartmentTradeId: '',
        declareState: '',
        certCheckResult: '',
        declareStartTime: '',
        declareUpdateTime: '',
        declareFinishTime: '',
      },
    }
  },
  created() {
    this.getConsultPayment()
  },
  methods: {
    getConsultPayment() {
      const { clientOrderNo, clientDeclareNo, customs, mchCustomsNo } =
        this.$route.query
      customsDeclare({
        clientDeclareNo,
        clientOrderNo,
        customs,
        mchCustomsNo,
      }).then((data) => {
        this.data = data
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 40px 0;
  background: #fff;
}
.header {
  text-align: center;
  .van-icon {
    font-size: 60px;
    color: #010101;
  }
  h4 {
    margin: 10px 0;
    font-size: 28px;
  }
}
.van-button {
  width: 80%;
  margin: 40px auto;
}
</style>
